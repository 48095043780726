// Helpers
import { privateApiUrl } from 'api/v3';
import { postRequest } from 'api/v3/helpers';

/**
 * Get discussion topic
 * @param {(string|number)} id
 * @param {Object} params
 * @param {string[]} params.include
 * @returns {string}
 */
export const getDiscussionTopicUrl = (id, params = { include: ['hero_image', 'project'] }) => {
  return privateApiUrl(`discussion_topics/${id}`, params);
};

/**
 * Get discussions of the discussion topic
 * @param {(number|string)} discussionTopId
 * @param {number} [pageNumber=1]
 * @param {number} [pageSize=25]
 * @param {string} [sort=-created_at]  - most_popular, -created_at, created_at
 * @param {object} filter
 * @param {boolean} [filter.approved=true]
 * @param {boolean} [filter.require_moderation=false]
 * @returns {string}
 */
export const getDiscussionTopicDiscussionsURL = (
  discussionTopId,
  pageNumber = 1,
  pageSize = 25,
  sort = '-created_at',
  filter = {
    require_moderation: false,
  }
) => {
  const params = {
    page: {
      number: pageNumber,
      size: pageSize,
    },
    sort,
    filter,
  };

  return privateApiUrl(`discussion_topics/${discussionTopId}/discussions`, params);
};

/**
 * Export comments for a DiscussionTopic
 * @param {(string|number)} discussionTopicId
 * @param {string} defaultLocale
 * @returns
 */
export const exportDiscussionTopicComments = (discussionTopicId, defaultLocale) => {
  const url = privateApiUrl(`discussion_topics/${discussionTopicId}/export_discussions`);

  return postRequest(url, {
    data: {
      type: 'export_discussions',
      attributes: {
        ...(defaultLocale && { defaultLocale }),
      },
    },
  });
};
